const breakpoints = [576, 768, 992, 1260]

export default {
  useCustomProperties: false,
  colors: {
    black: "#363840",
    blackAlt: "#434652",
    grey: "#989CA7",
    greyLight: "#EFF1F8",
    blue: "#3751FF",
    teal: "#29d8b9",
    background: "white",
    text: "black",
  },
  fonts: {
    body: "Josephin Sans, sans-serif",
    heading: "Josephin Sans, sans-serif",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  sizes: {
    navMobile: 70,
    navDesktop: 90,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  zIndices: {
    nav: 1000,
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  breakpoints: breakpoints.map(point => point + "px"),
  mq: breakpoints.map(bp => `@media (min-width: ${bp}px)`),
}
